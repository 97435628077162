import styled from "styled-components";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import Heading from "../Heading/Heading";
import NewsShort from "../NewsShort/NewsShort";

const Wrapper = styled.div`
position:relative;
`

const Description = styled.p`
margin:50px;
text-align:justify ;
`

const Masonry = styled.div`
padding:0 50px;
  column-count: 4;
  column-gap: 0.6em;
  @media (max-width: 1600px) {
    column-count: 3;
  }
  @media (max-width: 1000px) {
    column-count: 2;
  }
`;

const Image = styled.img`
  display: inline-block;
  margin:0.21em 0;
  width: 100%;
`;

const GalleryFriday = ()=>{
  window.scrollTo(0, 0);
    const Images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70]
    
    return (
        <Wrapper>
            <Navigation/>
            <Heading>FESTIWAL PASIBRZUCHA 2024 - Galeria zdjęć</Heading>
            <Description>
            </Description>
        <Masonry>
            {
                Images.map(el=>{
                    return(
                        <Image src={`/galeria_2024/festiwal_2024_${el}.jpg`}/>
                    );
                })
            }
            

        </Masonry>
        <NewsShort/>
        <Footer/>
        </Wrapper>
        
    );
}

export default GalleryFriday;