import styled from "styled-components";
import Heading from "../Heading/Heading";
import { news2025 } from "../../data";
// import { news } from "../../data";
import News from "./News";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import GalleryFriday2023Thumbnail from "../Gallery/GalleryFridayThumbnail";
import GallerySaturday2023Thumbnail from "../Gallery/GallerySaturdayThumbnail";
import GallerySunday2023Thumbnail from "../Gallery/GallerySundayThumbnail";
import Gallery2024Thumbnail from "../Gallery/Gallery2024Thumbnail";
import Aftermovie2023Thumbnail from "../Gallery/AftermovieThumbnail";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1400px) {
    padding: 50px 10vw;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  flex-wrap: wrap;
  @media screen and (min-width: 1400px) {
    padding: 0;
  }
`;

const AllNews = () => {
  const allnews = news2025;
  return (
    <>
      <Navigation />
      <Wrapper>
        <Heading>Aktualności</Heading>
        <NewsWrapper>
          {allnews.map((el) => (
            <News news={el} />
          ))}
          <Gallery2024Thumbnail />
          <Aftermovie2023Thumbnail />
          {/* <GallerySunday2023Thumbnail />
          <GallerySaturday2023Thumbnail />
          <GalleryFriday2023Thumbnail /> */}
          {/* {oldnews.map((el) => (
            <News news={el} />
          ))} */}
        </NewsWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

export default AllNews;
